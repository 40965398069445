.buttonWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.athlete {
  margin-left: 4px;
  font-weight: 500;
  text-transform: capitalize;
}

.centeredWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 8px;
}

.datePickerContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;

  &:nth-child(1) {
    margin-right: 10px;
  }

  &:nth-child(2) {
    margin-left: 10px;
  }
}

.datePickerContainer:nth-child(1) {
  margin-right: 10px;
  border: 1px solid black;
}

.datePicker {
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.6);
  width: 100%;
  outline: none;
  padding: 16px 20px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
}
