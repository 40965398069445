.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container {
    input {
        background-color: white;
        color: rgba(0, 0, 0, 0.6);
        outline: none;
        box-sizing: border-box;
        border: 0;
        padding: 12px 4px;
        border-radius: 4px;
        font-family: 'Roboto';
        font-weight: 500;
        font-size: 12px;
        text-align: center;
    }
    *:focus {
        outline: none;
    }
}

.react-datepicker__close-icon::after {
    background-color: #1d88f2 !important;
}