.loadingContainer {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.centeredStatsContainer {
  display: flex;
  margin-bottom: 16px;
  justify-content: center;
}

.actionContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.paginatedTable {
  display: flex;
  justify-content: center;
  margin-top: 8px;
}

.rosterButton {
  margin-left: auto;
}

.rosterSelect {
  height: 25px;
}
