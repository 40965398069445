.buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

.centeredWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}