.actionContainer {
  display: flex;
  align-items: center;
  margin: 8px;
}

.exportButton {
  margin-left: auto;
}

.filterContainer {
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.startDate {
  margin-right: 8px;
}

.filterButton {
  padding: 6px 15px;
}

.datePickerContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 8px;
  align-items: center;
}

.datePicker {
  margin-left: 8px;
  margin-right: 8px;
}

.filterButton {
  background-color: #1d88f2;
  padding: 12px 20px;
  font-size: 14px;
}
