@import "../../styles/colors";

.forgotPasswordContainer {
    margin: 8px auto;
    justify-content: center;
    display: flex;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
}

.forgotPasswordLink {
    color: $dashr-blue;
}