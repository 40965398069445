@import "../../../styles/colors";

.tableHead {
  background-color: #2e363f;
  cursor: pointer;
  
  tr {
    height: 60px;
    th {
      vertical-align: middle;
      padding-left: 20px;
      color: white;
    }
  }
}

.sortIcon {
  padding-left: 10px;
  color: $dashr-blue;
}
