.settingsContainer {
  display: flex;
  margin-top: 20px;
  align-items: center;
}

.titleDescriptionContainer {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.title {
  font-weight: 500;
}

.description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.deleteButton {
  margin-left: auto;
  padding: 8px 14px;
}
