.contentContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.message {
    font-weight: 500;
    font-size: 16px;
    text-align: center;
}

.note {
    font-size: 12px;
    text-align: center;
}

.buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}