.selectEventContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.centeredWrapper {
    display: flex;
    justify-content: center;
}

.fieldContainer {
    margin-top: 8px;
    margin-bottom: 8px;
}