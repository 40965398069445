.actionContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.reportSpotsContainer {
    display: flex;
    margin-bottom: 16px;
    justify-content: center;
  }
  

.pushRight {
    margin-left: auto;
}

.datePickerContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    margin-bottom: 8px;
    align-items: center;
  }
  
  .datePicker {
    margin-left: 8px;
    margin-right: 8px;
  }
  
  .filterButton {
    background-color: #1d88f2;
    padding: 12px 20px;
    font-size: 14px;
  }
  