.deleteMessage {
    text-align: center;
}

.buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}
  
.rosterName {
    text-transform: capitalize;
    font-weight: 500;
}