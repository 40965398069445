@import "../../styles/_colors.scss";

.searchBarContainer {
    display: flex;
    align-items: center;
    margin: 8px 0px;
}

.searchBar {
    background-color: white !important;
    color: rgba(0, 0, 0, 0.6) !important;
    width: 100% !important;
    outline: none !important;
    padding: 16px 20px !important;
    box-sizing: border-box !important;
    border: 0 !important;
    border-radius: 4px 0px 0px 0px !important;
    font-size: 16px !important;
    height: 50px !important;
}

.searchIconContainer {
    background-color: $dashr-blue !important;
    display: flex !important;
    align-items: center !important;
    padding: 10px 15px !important;
    border-radius: 0px 4px 4px 0px !important;
    cursor: pointer;
}

.searchIcon {
    color: white;
}