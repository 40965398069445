.menuItem {
    margin: 10px 0px;
}

.menuIcon {
    color: white;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
    line-height: 30px;
    float: left;
    margin-right: 20px;
}

.menuLink {
    margin: 10px 0px;
    display: block;
    margin: auto;
    width: 90%;
    padding: 2px 15px;
    color: #3c4858;
    color: rgba(255, 255, 255, 0.8);
    font-size: 13px;
    border-radius: 3px;
    text-transform: capitalize;
    &:hover {
        color: white;
        background-color: rgba(0, 0, 0, 0.1) !important;
    }
}

.menuLinkActive {
    background-color: #1d88f2 !important;
    color: white;
}

.menuLinkActive:hover {
    background-color: #1d88f2 !important;
}

.menuLabel {
    line-height: 30px;
    font-size: 14px;
    font-weight: 300;
}