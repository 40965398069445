.progressMessage {
    background-color: #23d160;
    visibility: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0px;
    margin: 8px 0px;
    color: white;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;

    &IsError {
        background-color: red;
    }
}