.sidePanel {
  width: 260px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  @media screen and (max-width: 1087px) {
    display: none;
  }
}

.contentPanel {
  background-color: #eee;
  margin-left: 260px;
  padding: 20px;
  min-height: 100vh;
  @media screen and (max-width: 1087px) {
    width: 100%;
    margin-left: 0px;
  }
}
