.navBar {
  background-color: transparent !important;
}

.hamburgerMenu {
  color: black;
}

.navbarMenu {
  background-color: transparent;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  box-shadow: none !important;
}

.navbarMenuLink {
  font-weight: 500 !important;
  color: white;
  /* background-color: rgba(255, 255, 255, 0.1); */
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: rgba(0, 0, 0, 0.7);
  margin: 5px 0px;
  border-radius: 4px;
  padding: 20px;
  display: block;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
}

.navbarMenuLogout {
  font-weight: 500 !important;
  color: white !important;
  /* background-color: rgba(255, 255, 255, 0.1); */
  /* background-color: rgba(0, 0, 0, 0.7); */
  background-color: rgba(0, 0, 0, 0.7);
  margin: 5px 0px;
  border-radius: 4px;
  padding: 20px;
  display: block;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
}

.logoutDropdown {
  cursor: pointer !important;
}
