.centeredWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.athleteRosterData {
    font-weight: 500;
    text-transform: capitalize;
}

.modalTitle {
    text-transform: capitalize;
}

.buttonWrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}