.input {
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.6);
    width: 100%;
    outline: none;
    padding: 16px 20px;
    box-sizing: border-box;
    border: 0;
    border-radius: 4px;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 16px;
}

.inputReadOnly {
    background-color: white;
}

.centeredText {
    text-align: center;
}