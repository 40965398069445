.detailWrapper {
  margin: 8px 0px;
}

.label {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-transform: uppercase;
}

.value {
  color: rgba(0, 0, 0, 0.8);
  width: 100%;
  outline: none;
  padding: 16px 0px;
  box-sizing: border-box;
  border: 0;
  border-radius: 4px;
  font-family: "Roboto";
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 20px;
}