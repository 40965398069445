.tableBody {
  tr {
    background-color: white;
    &:nth-child(even) {
      background-color: #f5f5f5;
    }
    &:hover {
      background-color: #f5f5f5;
      cursor: pointer;
    }

    td {
      height: 50px;
      padding-left: 20px;
      vertical-align: middle;
    }
  }
}
