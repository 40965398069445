
.background {
    background-image: url('../../assets//images/landingpagebg.jpg');
    height: 100vh;
    background-size: cover;
    background-position: center center;
    position: relative;
    top: 0;
    bottom: 0;
}

.overlay {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.65);
    top: 0;
    bottom: 0;
    width: 100%; 
}