.sideNavbarContainer {
    width: 100%;
    height: 100%;
    background-color: #2e363f;
}

.logoContainer {
    padding: 20px 0;
    text-align: center;
    width: 150px;
    margin: auto;
}

.logoHorizontalRule {
    padding: 0;
    width: 90%;
    margin: 0 auto;
    background-color: rgba(255, 255, 255, 0.3);
    height: 1px;
}

.menuContainer {
    height: 100%;
    margin-top: 20px;
    list-style: none;
}