.centeredWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.rosterMessageWrapper {
    margin-bottom: 16px;
}

.buttonWrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}