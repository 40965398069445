@import "../../styles/colors";

.button {
    background-color: $dashr-blue;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-family: 'Roboto';
    font-weight: 500;
    color: white;
    font-size: 15px;
    padding: 12px 20px;
    border-radius: 10px;
    margin: 8px;
    cursor: pointer;
    border: none;
}

.buttonFullWidth {
    display: flex;
    margin: 8px 0px;
    border-radius: 4px;
}

.deleteButton {
    background-color: red;
}

.backButton {
    background-color: #3c4858
}

.button:disabled {
    background-color: $dashr-blue-disabled;
}
