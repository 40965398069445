.newUserNavbar {
  background-color: transparent !important;
}

.logo {
  margin-right: 5px !important;
  max-height: 4rem !important;
}

.hamburgerMenu {
  color: white;
}

.navbarMenu {
  background-color: transparent;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  box-shadow: none !important;
}

.navbarMenuLink {
  font-family: Roboto !important;
  font-size: 12px !important;
  background-color: transparent !important;
  color: white;
  padding: 20px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
}

.navbarMenuTitle {
    font-weight: 500 !important;
    margin-left: 10px;
}

.mobileNavbarMenu {
  background-color: transparent;
  text-align: center;
  font-family: Roboto;
  font-size: 14px;
  box-shadow: none !important;
}

.mobileNavbarMenuItem {
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 500 !important;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  margin: 5px 0px;
  border-radius: 4px;
  padding: 20px;
  display: block;
  &:visited {
    color: white;
  }
  &:hover {
    color: white;
  }
  &:active {
    color: white;
  }
}
